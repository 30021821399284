<template>
  <div class="pt-3 px-3">
    <b-row class="relative">
      <b-col sm="6">
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          :v="v.form.name"
          v-model.trim="form.name"
        />
      </b-col>
      <b-col sm="6">
        <div class="position-icon" v-if="this.id !== '0'">
          <button type="button" class="btn-icon mr-1" @click="openModalLink">
            <font-awesome-icon icon="copy" />
          </button>
        </div>
        <InputText
          textFloat="Url Key"
          placeholder="Url Key"
          type="text"
          name="urlkey"
          isRequired
          :v="v.form.url_key"
          v-model.trim="form.url_key"
          @onKeypress="checkURLKey($event)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label class="header-title"
          >Campaign <span class="text-error">*</span></label
        >
        <b-form-group class="mt-2">
          <b-form-radio-group
            id="radio-group-1"
            v-model="selectedCampaign"
            :options="optionsCampaign"
            name="radio-options-1"
            :disabled="isRegister > 0"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <InputText
          textFloat="Limit Quantity Per Campaign"
          placeholder="Limit Quantity Per Campaign"
          type="number"
          name="limit"
          isRequired
          :v="v.form.quantity"
          v-model="form.quantity"
        />
      </b-col>
      <b-col>
        <InputText
          textFloat="Limit Quantity Per Person / Per Day"
          placeholder="Limit Quantity Per Person / Per Day"
          type="number"
          name="limit"
          isRequired
          :disabled="selectedCampaign != 3 || isRegister > 0"
          :v="v.form.user_limit"
          v-model="form.user_limit"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mb-15">
        <label class="header-title"
          >Valid From <span class="text-error">*</span></label
        >
        <datetime
          type="datetime"
          class="date-picker date-container"
          format="dd/MM/yyyy HH:mm:ss"
          placeholder="DD/MM/YYYY HH:mm:ss"
          value-zone="local"
          v-model="form.start_date"
          :min-datetime="dateFormat()"
          @close="changeStartDate"
        >
        </datetime>
        <div v-if="v.form.start_date.$error">
          <span class="text-error">Please select valid from.</span>
        </div>
      </b-col>
      <b-col sm="6" class="mb-15">
        <label class="header-title"
          >Valid To <span class="text-error">*</span></label
        >
        <datetime
          type="datetime"
          class="date-picker date-container"
          format="dd/MM/yyyy HH:mm:ss"
          placeholder="DD/MM/YYYY HH:mm:ss"
          value-zone="local"
          v-model="form.end_date"
          :min-datetime="form.start_date"
          @close="onChangeEndDate"
        >
        </datetime>
        <div v-if="v.form.end_date.$error">
          <span class="text-error">Please select valid to.</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label class="font-weight-bold main-label"> Customer Role </label>
        <div class="mt-2">
          <b-form-checkbox
            v-model="form.is_new_user"
            :value="1"
            :unchecked-value="0"
          >
            New Customer
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <TextEditorsTiny
          textFloat="Description"
          :rows="4"
          :value="form.description"
          :name="'description_' + 1"
          placeholder="Type something..."
          :v="v.form.description"
          @onDataChange="(val) => (form.description = val)"
          isRequired
        />
      </b-col>
    </b-row>

    <ModalCopyUrl ref="ModalCopyUrl" :urlWeb="linkWeb" :urlLine="linkLine" />
  </div>
</template>

<script>
import ModalCopyUrl from "@/components/form/modal/ModalCopyUrl";
import PrivilegeListPanel from "@/components/form/detail/PrivilegeListPanel";

export default {
  components: {
    PrivilegeListPanel,

    ModalCopyUrl,
  },
  props: {
    isRegister: {
      required: true,
    },
    form: {
      required: true,
      type: Object,
    },
    v: {
      require: true,
      type: Object,
    },
    list: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
    rowsPrivilege: {
      required: true,
      type: Number,
    },
    isBusyPrivilege: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
    linkWeb: {
      required: true,
      type: String,
    },
    linkLine: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedCampaign: 1,
      optionsCampaign: [
        { text: "Yearly Campaign", value: 1 },
        { text: "One-Time Campaign", value: 2 },
        { text: "Questionnaire Campaign", value: 3 },
      ],
      selectedSMS: 0,
      optionsSMS: [
        { text: "No", value: 0 },
        { text: "Yes", value: 1 },
      ],
      selectedRedeem: true,
      optionsRedeem: [
        { text: "None", value: true },
        { text: "Select Code", value: false },
      ],
      checkFirstShowOnly: false,
      message: "Copy These Text",
    };
  },
  watch: {
    selectedCampaign() {
      this.form.campaign_type_id = this.selectedCampaign;
      this.form.user_limit =
        this.selectedCampaign == 3 ? this.form.user_limit : 1;
    },
    selectedSMS() {
      this.form.send_sms = this.selectedSMS;
    },
  },
  created() {
    if (this.id !== "0") {
      this.selectedCampaign = this.form.campaign_type_id;
      this.selectedRedeem = this.form.privilege_id === 0 ? true : false;
      this.checkFirstShowOnly = true;
    }
  },
  methods: {
    checkURLKey(e) {
      let regex = new RegExp("^[a-zA-Z0-9_]+$");
      let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

      if (regex.test(str)) {
        return true;
      }

      e.preventDefault();
      return false;
    },
    openModalLink() {
      this.$refs.ModalCopyUrl.show();
    },
    validateForm() {
      this.v.form.$touch();
      if (this.v.form.$error) return;
      this.$emit("submitForm");
    },
    getPrivilegeListByPage(value) {
      this.$emit("getPrivilegeListByPage", value);
    },
    searchPrivilegeList(value) {
      this.$emit("searchPrivilegeList", value);
    },
    setShowDisplay(value) {
      this.$emit("setShowDisplay", value);
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    changeStartDate() {
      if (!this.form.end_date) {
        this.form.end_date = this.form.start_date;
      }
      if (this.form.start_date >= this.form.end_date) {
        this.form.end_date = this.$moment(this.form.start_date)
          .add("day", 1)
          .format();
      }
    },
    onChangeEndDate() {
      if (!this.form.start_date) {
        this.form.start_date = this.form.end_date;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  color: var(--font-main-color);
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
}
.pink-whitebutton {
  color: #fff;
  border-color: var(--primary-color);
  background-image: linear-gradient(var(--primary-color), #a000f0);
}
.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}
.position-icon {
  position: absolute;
  z-index: 999;
  left: 90px;
}
::v-deep .vdatetime-popup {
  z-index: 9999 !important;
}
::v-deep .date-container {
  input {
    color: var(--font-main-color) !important;
    border: 1px solid #d8dbe0 !important;
    border-radius: 0.25rem !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
  }
}
</style>
