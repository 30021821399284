<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <b-row class="no-gutters mb-3">
          <b-col>
            <div class="font-weight-bold ft-20 header-tablepage">
              CAMPAIGN FORM DETAIL
            </div>
          </b-col>
        </b-row>
        <div class="no-gutters bg-white">
          <b-tabs v-model="tabIndex">
            <b-tab title="Form Setting">
              <FormCampaignPanel
                :isRegister="isRegister"
                v-if="isLoadingDetail"
                :v="$v"
                :form="form"
                :list="privilegeList"
                :filter="filterPrivilege"
                :rowsPrivilege="rowsPrivilege"
                :isBusyPrivilege="isBusyPrivilege"
                @getPrivilegeListByPage="getPrivilegeListByPage"
                @searchPrivilegeList="searchPrivilegeList"
                @setShowDisplay="setShowDisplay"
                @submitForm="submitForm"
                :id="id"
                :linkLine="linkLine"
                :linkWeb="linkWeb"
              />
              <div class="no-gutters bg-white mt-3 py-2 px-3">
                <b-form-checkbox
                  switch
                  v-model="form.status"
                  class="radio-active"
                  value="1"
                  unchecked-value="0"
                  size="lg"
                >
                  <span class="ml-2 main-label">{{
                    form.status ? "Active" : "Inactive"
                  }}</span>
                </b-form-checkbox>
              </div>
            </b-tab>
            <b-tab title="Questionnaire">
              <FormQuestionList
                :isRegister="isRegister"
                v-if="!isLoadingProfile"
                :imageListShow="imageListShow"
                :deleteImageList="deleteImageList"
                :fields="fields"
                :profileType="profileType"
                :fieldTypes="fieldTypes"
                :form="form"
                :id="id"
                ref="FormQuestion"
              />
            </b-tab>
            <b-tab title="Report" v-if="id !== '0'">
              <FormReport
                :id="form.campaign_id"
                :form="form"
                :isRegister="rows"
            /></b-tab>
            <b-tab title="Setting">
              <FormResponse :form="form" />
            </b-tab>
            <b-tab title="Raw Response" v-if="id !== '0'">
              <DisplayDetailUserPanel
                :fields="fieldTableMerge"
                :items="items"
                :isBusy="isBusy"
                :rows="rows"
                :pageOptions="pageOptions"
                :showingTo="showingTo"
                :filter="filterTable"
                :id="form.campaign_id"
                @getListUser="getListUser"
                v-on:editCanpaign="editCanpaign"
                v-on:cancelCanpaign="cancelCanpaign"
                @getUserRegisterForm="getUserRegisterForm"
                v-on:approveCanpaign="approveCanpaign"
                :form="form"
                :userFill="userFill"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <FooterAction @submit="createdForm" routePath="/form" />
    </div>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import FormCampaignPanel from "@/components/form/detail/FormCampaignPanel";
import FormQuestionList from "@/components/form/detail/FormQuestionList";
import ModalAlertError from "@/components/modal/ModalAlertError";
import DisplayDetailUserPanel from "@/components/automation/detail/DisplayDetailUserPanel";
import FormSetting from "@/components/form/detail/FormSetting";
import FormReport from "@/components/form/report";
import FormResponse from "@/components/form/detail/FormResponse";
import _ from "lodash";

export default {
  components: {
    OtherLoading,
    FormCampaignPanel,
    ModalAlertError,
    FormQuestionList,
    DisplayDetailUserPanel,
    FormSetting,
    FormResponse,
    FormReport,
  },
  data() {
    return {
      isRegister: 0,
      headingText: "",
      descriptionText: "",
      fields: [
        {
          isProfile: false,
        },
      ],
      bufferInputs: [],
      bufferConditions: [],
      bufferMultipleChoice: [],
      bufferConditionSelects: [],
      fieldTypes: [
        { id: 1, name: "Field" },
        { id: 2, name: "Checkbox" },
        { id: 3, name: "Multiple Choice" },
        { id: 4, name: "Dropdown" },
        { id: 5, name: "DateTime" },
        { id: 6, name: "Branch dropdown" },
        { id: 7, name: "Date" },
        { id: 8, name: "Image" },
        { id: 10, name: "Title" },
        { id: 11, name: "Subtitle" },
        { id: 12, name: "Text Area" },
        { id: 13, name: "Space" },
      ],
      showCondition: [
        { value: 0, text: "Select Condition" },
        { value: 1, text: "Numberic" },
        { value: 2, text: "Character" },
      ],
      showSubCondition: [
        { value: 0, text: "Select" },
        { value: 1, text: "Length" },
        { value: 2, text: "Min-Max" },
      ],
      subMultipleChoice: [
        { value: 0, text: "Select" },
        { value: 1, text: "Size" },
        { value: 2, text: "Numberic" },
      ],
      form: {
        freezeQuantity: 0,
        name: "",
        description: "",
        url_key: "",
        campaign_type_id: 1,
        privilege_id: 0,
        start_date: "",
        end_date: "",
        send_sms: 0,
        sms_message: "",
        quantity: 0,
        user_limit: 1,
        is_new_user: 0,
        is_profile_display: 1,
        status: 1,
        fields: [],
        imageList: [],
        budget: 0,
        tracking_from: null,
        tracking_to: null,
        response_success:
          '<p style="text-align:center">ขอขอบคุณที่เข้าร่วมกิจกรรม</p>',
        response_fail: "เกิดข้อผิดพลาด ไม่สามารถร่วมกิจกรรมได้",
      },
      imageListShow: [],
      deleteImageList: [],
      canEdit: false,
      isBufferLoading: false,
      isLoading: true,
      id: this.$route.params.id,
      tabIndex: this.$route.query.edit == 1 ? 2 : 0,
      filterPrivilege: {
        show_only_select: 0,
        select_privilege: 0,
        delete_privilege: 0,
        search: "",
        page: 1,
        take: 5,
      },
      privilegeList: [],
      rowsPrivilege: 0,
      isBusyPrivilege: false,
      tabDisable: true,
      modalMessage: "",
      isLoadingDetail: false,
      profileType: [],
      isLoadingProfile: true,
      linkWeb: "",
      linkLine: "",
      firstFields: [],
      fieldsTable: [
        {
          key: "customer_id",
          label: "Customer ID",
        },
      ],
      fieldsTableDynamic: [],
      items: [],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingTo: 0,
      filterTable: {
        page: 1,
        take: 10,
        search: "",
        date: "",
        consent_list: [0, 1, 2, 6, 7],
        status_list: [0, 1, 2],
        SortColumnId: 0,
        sort_type: true,
        sortBy: "",
        sortDesc: "",
      },
      // fieldTableMerge: [],
      fieldTableMerge: [
        {
          key: "customerDetail",
          label: "Customer Detail",
          class: "w-150",
        },
        {
          key: "customField1",
          label: "",
          class: "w-150",
        },
        {
          key: "customField2",
          label: "",
          class: "w-150",
        },
        {
          key: "customField3",
          label: "",
          class: "w-150",
        },
        {
          key: "created_time",
          label: "Answer Date",
          class: "w-150",
          sortable: true,
        },
        {
          key: "is_consent",
          label: "Consent",
          thStyle: {
            width: "8%",
          },
        },
        {
          key: "status",
          label: "Status",
          thStyle: {
            width: "8%",
          },
        },
        {
          key: "manage",
          label: "",
          thStyle: {
            width: "10%",
          },
        },
      ],
      resultsSortFieldName: [],
      userFill: [],
      originFields: [],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        description: { required },
        url_key: { required },
        campaign_type_id: { required },
        privilege_id: { required },
        start_date: { required },
        end_date: { required },
        send_sms: { required },
        quantity: { required, minValue: minValue(this.form.freezeQuantity) },
        user_limit: { required },
        status: { required },
      },
      sms_message: { required },
    };
  },
  async created() {
    this.createDate();
  },
  methods: {
    async createDate() {
      if (this.id !== "0") {
        this.isLoading = true;
        await this.getFieldType();
        await this.getDetailForm();
        await this.getUserRegisterForm();
        this.tabDisable = false;
        this.filterPrivilege.show_only_select = 1;
      } else {
        this.isLoadingDetail = true;
      }
      await this.getListProfile();
    },
    async getFieldType() {
      await this.$store.dispatch("getFieldTypes");
      let data = this.$store.state.form.fieldtype;
      if (data.result == 1) {
        this.fieldTypes = data.detail.fieldTypes.filter((type) => type.id != 9);
      }
    },
    async getUserRegisterForm() {
      this.items = [];
      this.isBusy = true;
      let reqData = {
        id: this.form.campaign_id,
        filter: this.filterTable,
      };
      if (reqData.filter.date == "") {
        reqData.filter = {
          page: this.filterTable.page,
          take: this.filterTable.take,
          search: this.filterTable.search,
          consent_list: this.filterTable.consent_list,
          status_list: this.filterTable.status_list,
          sort_type: this.filterTable.sort_type,
        };
      }
      await this.$store.dispatch("getUserRegisterForm", reqData);
      let data = this.$store.state.form.userRegisterForm;
      let detail = data.detail?.detail;
      this.userFill = data.detail?.detail;

      let keyField = data.detail?.field_name;
      let countCustom = 3;
      // Fix this
      keyField.forEach((e) => {
        if (e.field_profile_type_id == 0) {
          if (countCustom == 3) {
            this.fieldTableMerge[1].label = e.key;
            this.fieldTableMerge[1].type = e.field_type_id;
          } else if (countCustom == 2) {
            this.fieldTableMerge[2].label = e.key;
            this.fieldTableMerge[2].type = e.field_type_id;
          } else if (countCustom == 1) {
            this.fieldTableMerge[3].label = e.key;
            this.fieldTableMerge[3].type = e.field_type_id;
          }
          --countCustom;
        }
      });
      let profileModel = [
        {
          model: "name",
          label: "ชื่อ",
          id: 3,
        },
        {
          model: "lastname",
          label: "นามสกุล",
          id: 5,
        },
        {
          model: "email",
          label: "อีเมล์",
          id: 11,
        },
        {
          model: "phone",
          label: "โทร",
          id: 10,
        },
        {
          model: "gender",
          label: "เพศ",
        },
        {
          model: "birthday",
          label: "วันเกิด",
          id: 9,
        },
      ];
      detail.forEach((item, index) => {
        let dataRow = {
          id: item.id,
          created_time: item.created_time,
          is_consent: item.is_consent,
          status: item.status,
          user_guid: item.user_guid,
        };
        item.user_fill.forEach((field, index2) => {
          for (let i = 0; i < profileModel.length; i++) {
            if (field.field_profile_type_id == profileModel[i].id) {
              dataRow[profileModel[i].model] = field.value;
            }
          }

          if (field.name == this.fieldTableMerge[1].label) {
            dataRow.customField1Type = this.fieldTableMerge[1].type;
            dataRow.customField1 = dataRow.customField1
              ? dataRow.customField1 +
                ", " +
                this.formatDataCustomField(
                  field.value,
                  dataRow.customField1Type
                )
              : this.formatDataCustomField(
                  field.value,
                  dataRow.customField1Type
                );
          } else if (field.name == this.fieldTableMerge[2].label) {
            dataRow.customField2Type = this.fieldTableMerge[2].type;
            dataRow.customField2 = dataRow.customField2
              ? dataRow.customField2 +
                ", " +
                this.formatDataCustomField(
                  field.value,
                  dataRow.customField2Type
                )
              : this.formatDataCustomField(
                  field.value,
                  dataRow.customField2Type
                );
          } else if (field.name == this.fieldTableMerge[3].label) {
            dataRow.customField3Type = this.fieldTableMerge[3].type;
            dataRow.customField3 = dataRow.customField3
              ? dataRow.customField3 +
                ", " +
                this.formatDataCustomField(
                  field.value,
                  dataRow.customField3Type
                )
              : this.formatDataCustomField(
                  field.value,
                  dataRow.customField3Type
                );
          }
        });
        this.items.push(dataRow);
      });
      this.rows = data.detail.count;
      this.isBusy = false;
      this.showingTo = this.items?.length >= 10 ? 10 : this.items.length;
      // }
    },
    formatDataCustomField(value, type) {
      if (type == 5) {
        return this.$moment(value).format("DD/MM/YYYY hh:mm:ss A");
      } else if (type == 7) {
        return this.$moment(value).format("DD/MM/YYYY");
      } else {
        return value;
      }
    },
    setShowDisplay(value) {
      if (value) {
        this.filterPrivilege.show_only_select = 1;
      } else {
        this.filterPrivilege.show_only_select = 0;
      }
      this.filterPrivilege.page = 1;
      // this.getPrivilegeList();
    },
    getPrivilegeListByPage(value) {
      this.filterPrivilege = value;
      // this.getPrivilegeList();
    },
    searchPrivilegeList(value) {
      this.filterPrivilege.search = value;
      this.filterPrivilege.page = 1;
      // this.getPrivilegeList();
    },
    getListUser(filter) {
      this.filterTable = filter;
      this.items = [];
      this.getUserRegisterForm();
    },

    async getListProfile() {
      try {
        this.isLoadingProfile = true;
        await this.$store.dispatch("getFieldProfiletypes");
        let data = this.$store.state.form.profileLists;
        if (data.result == 1) {
          this.isBufferLoading = true;
          if (this.id === "0") {
            this.fields = data.detail.fields;
            for (let i in this.fields) {
              this.profileType.push(this.fields[i]);
            }
          } else {
            data.detail.fields.forEach((field, index) => {
              const findField = this.fields.findIndex(
                (el) => el.field_profile_type_id == field.field_profile_type_id
              );

              if (findField == -1) {
                field.required = false;
                field.is_show = 0;
              }

              this.profileType.push(field);
            });
          }
          this.bufferConditions = [];
          this.bufferMultipleChoice = [];
          this.bufferConditionSelects = [];
          await this.addConditionToBuffer();
          this.isBufferLoading = false;
          this.addMultipleChoiceToBuffer();
          this.isLoading = false;
          this.isLoadingProfile = false;
        }
      } catch (error) {
        this.isBufferLoading = false;
        this.isLoading = false;
        this.isLoadingProfile = false;
      }
    },
    async getDetailForm() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("getFormDetail", this.id);
        let data = this.$store.state.form.formDetail;
        if (data.result == 1) {
          this.isBufferLoading = true;
          this.isRegister = data.detail.result.is_register;
          this.form = data.detail.result.form;

          if (data.detail.result.is_register >= 1) {
            this.form.freezeQuantity = Number(data.detail.result.form.quantity);
          } else {
            this.form.freezeQuantity = 0;
          }
          // hard code
          this.form.imageList = [];
          this.isLoadingDetail = true;
          this.fields = data.detail.result.form.fields;
          this.fields.forEach((e) => {
            if (e.field_profile_type_id != 0 && e.required) {
              e.is_show = 1;
            }
          });
          // this.firstFields = data.detail.result.form.fields;
          this.originFields = _.cloneDeep(this.fields);
          this.linkWeb = data.detail.result.urlKey_web;
          this.linkLine = data.detail.result.urlKey_line;
          if (this.form.privilege_id !== 0) {
            this.filterPrivilege.select_privilege = this.form.privilege_id;
            this.checkFirstShowOnly = true;
            // this.getPrivilegeList();
          }
          this.bufferConditions = [];
          this.bufferMultipleChoice = [];
          this.bufferConditionSelects = [];
          await this.addConditionToBuffer();
          this.isBufferLoading = false;
          this.addMultipleChoiceToBuffer();
          this.isLoading = false;
        }
      } catch (error) {
        this.isBufferLoading = false;
        this.isLoading = false;
      }
    },
    async addConditionToBuffer() {
      try {
        await this.fields.forEach(async (element, index) => {
          this.bufferConditions.push([]);
          this.bufferMultipleChoice.push({});
          this.bufferConditionSelects.push([]);
        });
        await this.fields.forEach(async (element, index) => {
          if (element.field_conditions.length > 0) {
            element.field_conditions.forEach((ele) => {
              if (ele) {
                this.bufferConditions[index].push({
                  field_condition_type_id: ele.field_condition_type_id,
                  value: ele.value,
                });
              }
              this.bufferConditionSelects[index].push([0, 0]);
            });
          } else {
            this.bufferConditionSelects[index].push([0, 0]);
          }
        });
        await this.bufferConditions.forEach(async (element, index) => {
          element.forEach((ele) => {
            if (ele.field_condition_type_id == 1) {
              if (ele.value == "text") {
                this.bufferConditionSelects[index][0] = 2;
                this.bufferConditionSelects[index][1] = 1;
              } else if (ele.value == "number" || ele.value == "Number") {
                this.bufferConditionSelects[index][0] = 1;
              } else {
                this.bufferConditionSelects[index][0] = 0;
              }
            }
            if (ele.field_condition_type_id == 2 && ele.value != "") {
              this.bufferConditionSelects[index][1] = 1;
            } else if (ele.field_condition_type_id == 3 && ele.value != "") {
              this.bufferConditionSelects[index][1] = 2;
            } else if (ele.field_condition_type_id == 4 && ele.value != "") {
              this.bufferConditionSelects[index][1] = 2;
            } else if (
              ele.value == "" &&
              this.bufferConditionSelects[index][1] != 1 &&
              this.bufferConditionSelects[index][1] != 2
            ) {
              this.bufferConditionSelects[index][1] = 0;
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

    checkForm() {
      let check = false;
      let checkIndex = 0;
      this.$refs.FormQuestion.questionList.forEach((q, index) => {
        if (q.field_type_id == 0) {
          q.profile.forEach((p) => {
            if (p.required && !p.name) {
              check = true;
              checkIndex = index;
            }
          });
        } else {
          if (q.field_type_id >= 2 && q.field_type_id <= 4) {
            q.field_choices.forEach((c) => {
              if (!c.name) {
                check = true;
                checkIndex = index;
              }
            });
          }
          if (
            q.field_type_id != 9 &&
            q.field_type_id != 10 &&
            q.field_type_id != 11 &&
            q.field_type_id != 13 &&
            q.field_type_id != 14
          ) {
            if (!q.name) {
              check = true;
              checkIndex = index;
            }
          }
        }
      });
      return { isCheck: check, index: checkIndex };
    },
    sortField() {
      this.$refs.FormQuestion.questionList.forEach(async (q, index) => {
        q.sort_order = await index;
      });
    },
    mapField() {
      let branch = this.$refs.FormQuestion.branchList;
      let resultBranchAll = 0;
      for (let b in branch) {
        resultBranchAll = branch[b].count + resultBranchAll;
      }
      let statusIndex = this.fields.findIndex(
        (field) => field.field_type_id == 9
      );
      let fieldStatus = this.fields[statusIndex];

      if (statusIndex == -1) {
        fieldStatus = {};
      }
      this.fields = [];
      this.$refs.FormQuestion.questionList.forEach((q) => {
        if (q.field_type_id == 0) {
          q.profile.forEach((p) => {
            if (p.is_show) {
              p.is_show = p.is_show;
              p.select_branch = [];
              this.fields.push(p);
            }
          });
        } else {
          if (q.field_type_id == 6) {
            if (!q.select_branch) {
              q.select_branch = [];
            } else if (q.select_branch.length === resultBranchAll) {
              q.select_branch = [0];
            }
            delete q.branchList;
          } else {
            q.select_branch = [];
          }
          this.fields.push(q);
        }
      });
      if (Object.keys(fieldStatus).length != 0) {
        fieldStatus.select_branch = [];
        fieldStatus.sort_order = this.fields.length;
        this.fields.push(fieldStatus);
      }
    },
    checkChange() {
      for (const [index, obj] of this.form.fields.entries()) {
        if (this.originFields[index]) {
          if (obj.field_choices) {
            if (
              !_.isEqual(
                obj["field_choices"],
                this.originFields[index]["field_choices"]
              )
            ) {
              obj.is_update = 1;
            }
          }
          for (const key in obj) {
            if (key == "select_branch" || key == "sort_order") break;
            // console.log(this.originFields[index][key], obj[key]);
            if (!_.isEqual(obj[key], this.originFields[index][key])) {
              obj.is_update = 1;
              break;
            }
          }
        }
      }
    },
    async createdForm() {
      this.form.response_success =
        this.form.response_success == ""
          ? '<p style="text-align:center">ขอขอบคุณที่เข้าร่วมกิจกรรม</p>'
          : this.form.response_success;
      this.form.response_fail =
        this.form.response_fail == ""
          ? "เกิดข้อผิดพลาด ไม่สามารถร่วมกิจกรรมได้"
          : this.form.response_fail;
      try {
        let check = await this.checkForm();
        this.$refs.FormQuestion.isSubmit = true;
        if (check.isCheck) {
          // this.modalMessage = "กรุณากรอกคำถามให้ถูกต้อง";
          this.tabIndex = 1;
          this.$refs.FormQuestion.questionActive = check.index;
          // this.$refs.modalAlertError.show();
          this.errorAlert("กรุณากรอกคำถามให้ถูกต้อง");
          return;
        }
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          this.tabIndex = 0;
          return;
        }

        this.$EventBus.$emit("showLoading");
        await this.sortField();
        await this.mapField();
        this.form.fields = this.fields;

        this.isLoading = true;
        this.form.tracking_from =
          this.form.tracking_from == "" ? null : this.form.tracking_from;
        this.form.tracking_to =
          this.form.tracking_to == "" ? null : this.form.tracking_to;
        if (this.id === "0") {
          await this.$store.dispatch("createdForm", this.form);
          let data = this.$store.state.form.respCreateForm;
          if (data.result == 1) {
            this.$EventBus.$emit("hideLoading");
            this.successAlert().then(() => {
              this.$router.push("/form");
            });
          } else {
            this.$EventBus.$emit("hideLoading");
            this.isLoading = false;

            this.errorAlert(data.message);
          }
        } else {
          this.checkChange();

          this.form.id = parseInt(this.id);
          await this.$store.dispatch("updateForm", this.form);
          let data = this.$store.state.form.respUpdateForm;
          if (data.result == 1) {
            this.isLoading = false;
            this.$nextTick(() => {
              this.tabIndex--;
            });
            this.$EventBus.$emit("hideLoading");
            this.successAlert().then(() => {
              this.$router.push("/form");
            });
          } else {
            this.$EventBus.$emit("hideLoading");
            this.isLoading = false;
            this.errorAlert(data.message);
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.$EventBus.$emit("hideLoading");
        console.error(error);
        this.isLoading = false;
      }
    },
    submitForm() {},
    editCanpaign() {},
    cancelCanpaign() {},
    approveCanpaign() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .fr-box.fr-basic {
  position: sticky;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: #e2dff3;
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.ft-20 {
  font-size: 20px;
}
#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
  z-index: 1040;
}
::v-deep .date-container {
  input {
    color: var(--font-main-color) !important;
    border: 1px solid #d8dbe0 !important;
    border-radius: 0.25rem !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
  }
}
</style>
