<template>
  <div class="mx-3">
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      :placeholder="'Search Name'"
      @searchAll="getReport()"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option>
        <div class="mt-3">
          <div>
            <div class="font-weight-bold mb-2">Date From</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.create_from"
                ref="startDate"
                @input="handleMinDate"
                placeholder="Please Select Date"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="font-weight-bold mb-2">Date To</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.create_to"
                :min-datetime="filter.create_from"
                placeholder="Please Select Date"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <InputSelect
              title="Age Group"
              name="age-group-options"
              isRequired
              v-model="filter.age_group"
              v-bind:options="options.ageGroups"
              valueField="id"
              textField="name"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0" disabled
                  >-- Select Choice --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
          <div class="mb-3">
            <InputSelect
              title="Gender"
              name="gender-options"
              isRequired
              v-model="filter.gender"
              v-bind:options="options.gender"
              valueField="id"
              textField="name"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >-- Select Choice --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
        </div>
      </template>
    </SideBarFilter>
    <div class="p-3 my-3 pre-box title-panel">
      <b-row>
        <b-col sm="6" class="border-right">
          <label>Campaign Response</label>
          <div>
            <span class="text-score">
              {{ isRegister | numeral("0,0") }}
            </span>
          </div>
        </b-col>

        <b-col sm="6">
          <label>Total Customer</label>
          <div>
            <span class="text-score">
              {{ form.total_customer | numeral("0,0") }}

              <div class="badge badge-score">
                ({{ form.total_new_customer }} New User)
              </div>
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!isLoading">
      <b-col cols="12"
        ><b-card class="border-none card-shadow">
          <div class="content-between">
            <div class="chart-title-name">Campaign Response</div>
            <b-button @click.prevent="sidebarFilter" class="btn-filter mr-2">
              <font-awesome-icon icon="filter" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="filter" class="pointer" />
                <span class="btn-text">Filter</span>
              </span>
            </b-button>
          </div>
          <Chart
            v-if="!isChartFetch"
            id="response-chart"
            :chartData="chartData.responseChart.data"
            text="Number Of responses"
            class="mt-auto"
            type="line"
            :label="chartData.responseChart.label"
            :hideLegend="true"
            xText="Date"
          />
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card class="border-none card-shadow">
          <div class="d-flex justify-content-between">
            <div class="chart-title-name">Age</div>
            <div class="d-flex" style="column-gap: 5px">
              <div
                :class="`f-14 underline cursor-pointer`"
                @click="ageGenderExport('age')"
              >
                <span class="text-highlight">Export</span>
              </div>
            </div>
          </div>
          <Chart
            v-if="!isChartFetch"
            id="age-chart"
            :chartData="chartData.ageChart.data"
            text="Number Of Responses"
            class="mt-auto"
            :label="chartData.ageChart.label"
            :hideLegend="true"
            xText="Age Group"
          />
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card class="border-none card-shadow">
          <div class="d-flex justify-content-between">
            <div class="chart-title-name">Gender</div>
            <div class="d-flex" style="column-gap: 5px">
              <div
                :class="`f-14 underline cursor-pointer`"
                @click="ageGenderExport('gender')"
              >
                <span class="text-highlight">Export</span>
              </div>
            </div>
          </div>
          <Chart
            v-if="!isChartFetch"
            id="gender-chart"
            :chartData="chartData.genderChart.data"
            text="Number Of Responses"
            class="mt-auto"
            :label="chartData.genderChart.label"
            :hideLegend="true"
            xText="Gender"
        /></b-card>
      </b-col>

      <b-col
        cols="12"
        v-for="(chartFieldChoice, n) of chartData.fieldchoiceReponse"
        :key="'chart' + chartFieldChoice.text"
      >
        <b-card class="border-none card-shadow">
          <div class="f-16 mb-3 content-between">
            <b>{{ chartFieldChoice.text }}</b>
            <InputSelect
              title=""
              name="type"
              isRequired
              class="mt-2"
              :value="chartFieldChoice.value"
              v-bind:options="chartFieldChoice.options"
              valueField="id"
              @onDataChange="handleFilterChange($event, n, chartFieldChoice)"
              textField="name"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >-- Select Choice --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
          <Chart
            v-if="!isChartFetch"
            :id="chartFieldChoice.text + '-chart'"
            :chartData="chartFieldChoice.data"
            text="Number Of Responses"
            class="mt-auto"
            :label="chartFieldChoice.label"
            :hideLegend="true"
          />
          <div v-else>
            <OtherLoading />
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        v-for="n of chartData.fieldTextResponse"
        :key="'chart' + n.id"
      >
        <b-card class="border-none card-shadow">
          <div class="f-16 mb-3 content-between">
            <b>{{ n.field_name }}</b>
            <div class="d-flex" style="column-gap: 5px">
              <div
                :class="`f-14 underline cursor-pointer`"
                @click="toggleModal(n.id)"
              >
                <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                <span class="text-highlight">Export</span>
              </div>
            </div>
          </div>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="n.user_answer"
            :busy="isBusy"
            @row-clicked="handleSelectStaff($event)"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :filter="filter"
            :rows="n.user_answer_count"
            @pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="formExport"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (formExport.email = val)"
      @submit="exportCustomer"
    />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import Chart from "@/components/form/chart";
import Multiselect from "vue-multiselect";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: { Chart, ModalInputEmail, OtherLoading, Multiselect },
  props: {
    id: {
      required: true,
    },
    form: {
      required: true,
    },
    isRegister: {
      required: false,
    },
  },
  data() {
    return {
      chartData: {
        responseChart: { data: [], label: [] },
        ageChart: { data: [], label: [] },
        genderChart: { data: [], label: [] },
        fieldchoiceReponse: [],
        fieldTextResponse: [],
      },
      isLoading: true,
      fields: [
        {
          key: "user_name",
          label: "Customer Name",
          thClass: "t-w-30",
        },
        {
          key: "value",
          label: "Answer",
          thClass: "t-w-70",
        },
      ],
      items: [],
      isBusy: false,
      filter: {
        page: 1,
        take: 10,
        create_from: "01-01-0001",
        create_to: "01-01-0001",
        filter: [],
        gender: "",
        age_group: 0,
      },
      rows: 0,
      isChartFetch: false,
      ageResponse: [],
      genderResponse: [],
      options: {
        ageGroups: [],
        gender: [],
      },
      formExport: {
        email: "",
        filter: null,
        gender: null,
        age_group: null,
        create_from: null,
        create_to: null,
        campaign_id: null,
        text_field_id: null,
      },
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    handleChangeTake(val) {
      this.filter.take = val;
      this.filter.page = 1;
      this.getReport();
    },
    pagination(val) {
      this.filter.page = val;

      this.getReport();
    },
    handleFilterChange(value, index, obj) {
      this.isChartFetch = true;
      this.chartData.fieldchoiceReponse[index].value = value;

      this.chartData.fieldchoiceReponse = this.chartData.fieldchoiceReponse.map(
        (n) => {
          return { ...n, value: n.value == "" ? 0 : n.value };
        }
      );

      this.filter.filter = this.chartData.fieldchoiceReponse.map((n) =>
        n.value == "" ? n.options[0].id : n.value
      );
      this.getReport(value);
    },
    async getReport() {
      try {
        this.filter.create_from =
          this.filter.create_from == ""
            ? "01-01-0001"
            : this.filter.create_from;
        this.filter.create_to =
          this.filter.create_to == "" ? "01-01-0001" : this.filter.create_to;
        var payload = { ...this.filter };
        payload.age_group = parseInt(payload.age_group);
        payload.filter = payload.filter.every((el) => el === 0)
          ? []
          : payload.filter;
        const result = await this.$Axios.post(
          `${process.env.VUE_APP_API}/Form/getreport/${this.id}`,
          payload
        );

        let n = 0;
        let dataChoice = [];
        this.options = {
          ageGroups: result.data.detail.ageGroupFilter,
          gender: result.data.detail.genderFilter,
        };
        this.genderResponse = result.data.detail.genderResponse;
        this.ageResponse = result.data.detail.ageResponse;
        for (const choice of result.data.detail.fieldchoiceReponse) {
          let data = [];
          let label = choice.filter.map((el) => el.name);
          for (const sum of label) {
            if (sum == "Best Eco-Friendly") {
              data.push({ text: "BEST ECO-FRIENDLY BEAUTY", value: 0 });
            } else data.push({ text: sum, value: 0 });
          }

          for (const answer of choice.user_answer) {
            let index = data.findIndex(
              (el) => el.text.toLowerCase() == answer.value.toLowerCase()
            );

            if (index != -1) {
              data[index].value += parseInt(answer.user_count);
            }
          }
          var realLabel = [];
          var realData = [];

          for (const { value, text } of data) {
            if (value != 0) {
              realLabel.push(text);
              realData.push(value);
            }
          }

          dataChoice.push({
            text: choice.field_name,
            options: choice.filter,
            value: this.chartData.fieldchoiceReponse[n]
              ? this.chartData.fieldchoiceReponse[n].value
              : "",
            data: [
              {
                label: "Number Of Responses",
                data: realData,
                borderColor: "rgb(168,97,203)",
                backgroundColor: "rgb(168,97,203)",
                fill: false,
                pointRadius: 0,
              },
            ],
            label: realLabel,
          });
          n++;
        }

        dataChoice.map((el) => {
          el.options.unshift({ id: 0, name: "All" });
        });
        let temp = {
          responseChart: {
            data: [
              {
                label: "Number Of Responses",

                data: [
                  0,
                  ...result.data.detail.campaignResponse.map((el) => el.user),
                ],
                borderColor: "rgb(168,97,203)",
                backgroundColor: "rgb(168,97,203)",
                fill: false,
                pointRadius: 0,
                tension: 0.1,
              },
            ],
            label: [
              0,
              ...result.data.detail.campaignResponse.map((el) =>
                this.$moment(el.date).format("DD/MM/YYYY")
              ),
            ],
          },
          ageChart: {
            data: [
              {
                label: "Number Of Responses",
                type: "bar",
                data: result.data.detail.ageResponse.map((el) => el.user),
                borderColor: "rgb(168,97,203)",
                backgroundColor: "rgb(168,97,203)",
                fill: false,
              },
            ],
            label: result.data.detail.ageResponse.map((el) => el.age_name),
          },
          genderChart: {
            data: [
              {
                label: "Number Of Responses",
                type: "bar",
                data: result.data.detail.genderResponse.map((el) => el.user),
                borderColor: "rgb(209,165,230)",
                backgroundColor: "rgb(209,165,230)",
                fill: false,
              },
            ],
            label: result.data.detail.genderResponse.map(
              (el) => el.gender_name
            ),
          },
          fieldchoiceReponse: dataChoice,
          fieldTextResponse: result.data.detail.fieldTextResponse,
        };
        this.chartData = JSON.parse(JSON.stringify(temp));

        this.isChartFetch = false;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    handleMinDate() {
      let diff = this.$moment(this.filter.create_to).diff(
        this.$moment(this.filter.create_from),
        "min"
      );

      if (diff < 0) this.filter.create_to = "";
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    clearFilter() {
      this.filter = {
        page: 1,
        take: 10,
        create_from: "01-01-0001",
        create_to: "01-01-0001",
        filter: "",
      };
      this.getReport();
      this.$refs.sidebarFilter.hide();
    },
    async ageGenderExport(val) {
      let url = `${process.env.VUE_APP_API}/`;
      let payload = { campaign_id: this.id };
      if (val == "gender") {
        url += "form/exportreportgender";
        payload.gender_response = this.genderResponse;
      } else if (val == "age") {
        url += "form/exportreportagegroup";
        payload.agegroup_response = this.ageResponse;
      }
      this.$EventBus.$emit("showLoading");
      const result = await this.$Axios.post(url, payload, {
        responseType: "blob",
      });

      var fileURL = window.URL.createObjectURL(new Blob([result.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", val + "-report" + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$EventBus.$emit("hideLoading");
    },
    async exportCustomer() {
      let url = `${process.env.VUE_APP_API}/form/exportreporttextfield`;
      let payload = {
        ...this.formExport,
        ...this.filter,
        campaign_id: this.id,
      };

      this.$EventBus.$emit("showLoading");
      const result = await this.$Axios.post(url, payload);
      this.successAlert(
        "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
      );

      this.$EventBus.$emit("hideLoading");
    },
    toggleModal(id) {
      console.log(id);
      this.formExport.text_field_id = id;
      this.$refs.ModalInputEmail.show();
    },
    clearExportFilter() {
      this.formExport = {
        email: "",
        filter: null,
        gender: "",
        age_group: 0,
        create_from: null,
        create_to: null,
        campaign_id: null,
        text_field_id: null,
      };
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}

.t-w-30 {
  width: 30%;
}
.t-w-70 {
  width: 70%;
}
</style>
